<template>
  <div class="card">
    <!-- Accordion Header -->
    <div class="card-header p-0" v-bind:id="componentId + '-head'">
      <h2 class="mb-0">
        <button
          class="btn btn-white btn-block text-left p-3 collapsed"
          type="button"
          data-toggle="collapse"
          v-bind:data-target="'#' + componentId + '-collapse'"
          aria-expanded="true"
          v-bind:aria-controls="componentId + '-collapse'"
        >
          <h5 class="custom-card-title">
            <i v-if="icon" v-bind:class="icon"></i>
            <span>{{ getTitle }}</span>
            <i
              class="fas fa-chevron-down float-right font-14 mt-1 text-muted"
            ></i>
          </h5>
        </button>
      </h2>
    </div>
    <!-- Accordion Header -->

    <!-- Product Accordion Body -->
    <div
      v-bind:id="componentId + '-collapse'"
      class="collapse"
      v-bind:class="{ show: !collapsed }"
      v-bind:aria-labelledby="componentId + '-head'"
    >
      <div class="card-body">
        <div class="row" v-if="searchableProduct">
          <div class="col">
            <div class="position-relative"  v-click-outside="onClose">
              <!-- Search Product -->
              <input
                type="text"
                v-bind:id="componentId + '-head'"
                class="form-control"
                v-bind:placeholder="$t('searchProduct')"
                v-model="searchTerm"
                v-on:input="onInput"
                v-on:focus="selectDropVisible = true"
                v-on:blur="onBlur"
                ref="searchInput"
              />
              <!-- ./Search Product -->

              <!-- Result Count -->
              <div
                class="result-count"
                v-if="searchTerm && searchTerm.length >= length"
              >
                <div v-if="!ready">
                  <i class="fas fa-spinner fa-spin"></i>
                </div>
                <div v-else>
                  <div
                    v-if="
                      searchTerm.length &&
                      filteredProductList &&
                      filteredProductList.length
                    "
                  >
                    <span class="badge badge-pill alert-primary"
                      >{{ filteredProductList.length }} {{ $t("result") }}</span
                    >
                  </div>
                  <div v-else>
                    <span class="badge badge-pill alert-dark">{{
                      $t("thereWereNoResults")
                    }}</span>
                  </div>
                </div>
              </div>
              <!-- ./Result Count -->

              <!-- Product Result Card -->
              <div
                class="
                  dropdown-menu
                  shadow-sm
                  col-12
                  custom-scrollbar
                  result-card
                "
                
                v-bind:class="{
                  show: filteredProductList.length && selectDropVisible,
                }"
              >
                <span
                  class="btn dropdown-item py-2"
                  v-for="product in filteredProductList"
                  v-bind:key="product.id"
                  v-on:click="selectProduct(product)"
                >
                  <div class="row align-items-center">
                    <div
                      class="col-auto"
                      v-if="name !== 'cross' && name !== 'compatible'"
                    >
                      <img
                        v-if="checkImageExists(product)"
                        v-bind:src="getImageSrc(product)"
                        width="50"
                        class="img-fluid"
                      />
                      <img
                        v-else
                        src="https://www.samsung.com/etc/designs/smg/global/imgs/support/cont/NO_IMG_600x600.png"
                        width="50"
                        height="50"
                        alt=""
                      />
                    </div>
                    <div class="col-auto">
                      <input
                        type="checkbox"
                        v-bind:checked="
                          computedData.filter((A) => A.id == product.id)
                            .length > 0
                        "
                        v-on:chnage="selectProduct(product)"
                        v-if="multipleSelect"
                      />
                      <template v-if="product.sku"
                        >{{ product.sku }} -
                      </template>
                      <template v-if="product.code"
                        >{{ product.code }} -
                      </template>
                      <template class="compitable" v-if="name === 'compatible'">
                        <span>{{ product.sku_no }}</span>
                        <span style="font-weight: 500">{{ product.name }}</span>
                        <span
                          >{{ product.currency_code.symbol }}{{ product.price }}
                        </span>
                      </template>
                      <template v-else>
                        {{ product.name }}
                      </template>
                    </div>
                  </div>
                </span>
              </div>
              <!-- Product Result Card -->
            </div>
          </div>
          <div class="col-auto" v-if="create">
            <button class="btn btn-info" v-on:click="onCreate">
              <i class="fas fa-plus fa-sm"></i> {{ $t("add") }}
            </button>
          </div>
        </div>
        <!-- Product List -->

        <div
          class="custom-scrollbar mt-3"
          v-bind:class="{ 'accordion-overflow': bodyOverflow }"
          v-if="computedData && computedData.length"
        >
          <WeTable
            v-bind:index="false"
            v-bind:data="computedData"
            v-bind:columns="columns"
            v-bind:actions="actions"
            v-bind:searchable="searchable"
            v-bind:searchText="searchText"
            v-bind:limitable="limitable"
            v-bind:pageDetails="false"
            v-bind:paginatable="computedData.length > itemsPerPage"
            v-bind:itemsPerPage="itemsPerPage"
            v-bind:selectable="false"
            v-bind:row-class="'font-14'"
            v-on:on-action="onAction"
            v-on:qty-change="onQtyChange"
            v-on:price-change="priceChange"
            v-bind:responsive="bodyOverflow"
          >
            <template slot="footer" v-if="quantity && showTotal">
              <!-- <tr>
                <td v-bind:colspan="columns.length" class="border-0 text-right">
                  <div class="row">
                    <div class="col">
                      <span class="font-weight-bold">Toplam Tutar:</span>
                    </div>
                    <div class="col-2">{{ totalPrice }}</div>
                  </div>
                </td>
              </tr> -->

              <tr>
                <td v-bind:colspan="columns.length" class="border-0 text-right">
                  <div class="row">
                    <div class="col">
                      <span class="font-weight-bold"
                        >{{ $t("subTotal") }}:</span
                      >
                    </div>
                    <div class="col-2">{{ subTotal }}</div>
                  </div>
                </td>
                <td class="border-0"></td>
              </tr>
              <!-- <tr>
                <td
                  v-if="discountPrice && discountPrice !== subTotal"
                  v-bind:colspan="columns.length"
                  class="border-0 text-right"
                >
                  <div class="row">
                    <div class="col">
                      <span class="font-weight-bold"
                        >{{ $t("discountPrice") }}:</span
                      >
                    </div>
                    <div class="col-2">{{ discountPrice }}</div>
                  </div>
                </td>
              </tr> -->
              <!-- <tr v-if="campaignDiscount">
                <td v-bind:colspan="columns.length" class="border-0 text-right">
                  <div class="row">
                    <div class="col">
                      <span class="font-weight-bold"
                        >{{ $t("campaignDiscount") }}:</span
                      >
                    </div>
                    <div class="col-2">{{ campaignDiscountPrice }}</div>
                  </div>
                </td>
                <td class="border-0"></td>
              </tr> -->
              <tr>
                <td v-bind:colspan="columns.length" class="border-0 text-right">
                  <div class="row">
                    <div class="col">
                      <span class="font-weight-bold">{{ $t("vat") }}:</span>
                    </div>
                    <div class="col-2">{{ vat }}</div>
                  </div>
                </td>
                <td class="border-0"></td>
              </tr>
              <tr>
                <td v-bind:colspan="columns.length" class="border-0 text-right">
                  <div class="row">
                    <div class="col">
                      <span class="font-weight-bold"
                        >{{ $t("grandTotal") }}:</span
                      >
                    </div>
                    <div class="col-2">{{ totalPrice }}</div>
                  </div>
                </td>
                <td class="border-0"></td>
              </tr>
            </template>
          </WeTable>
        </div>
        <!-- ./Product List -->
      </div>
    </div>

    <!-- ./Product Accordion Body -->
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "WeProduct",
  props: {
    columns: {
      type: Array,
      default: () => [
        { name: "image", icon: "fas fa-camera", type: "image" },
        { name: "sku_no", th: "Stok Kodu", type: "string" },
        { name: "name", th: "Ürün Adı", type: "string" },
        { name: "price", th: "Fiyatı", type: "currency" },
      ],
    },
    productId: {
      default: null,
    },
    multipleSelect: {
      default: false,
    },
    actions: {
      type: Array,
      default: () => [
        {
          icon: "fas fa-trash fa-sm",
          class: "btn-outline-danger border-0 ml-2",
          action: "remove",
        },
      ],
    },
    resultHeight: {
      default: "auto",
    },
    showTotal: {
      default: false,
    },
    searchText: {
      default: "Çapraz Ürün Ara",
    },
    quantity: {
      type: Boolean,
      default: false,
    },
    limit: {
      default: 0
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    searchableProduct: {
      type: Boolean,
      default: true,
    },
    limitable: {
      type: Boolean,
      default: false,
    },
    paginatable: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    create: {
      type: Boolean,
      default: false,
    },
    name: {
      default: "",
    },
    title: {
      default: "",
    },
    icon: {
      default: "",
    },
    length: {
      default: 3,
    },
    delay: {
      default: 1000,
    },
    products: {
      default: () => [],
    },
    removeSelf: {
      default: true,
    },
    collapsed: {
      default: false,
    },
    showVat: {
      default: false,
    },
    bodyOverflow: {
      default: true,
    },
    campaignDiscount: {
      default: 0,
    },
  },
  data() {
    return {
      ready: false,
      searchTerm: "",
      filteredProductList: [],
      selectDropVisible: false,
      totalPrice: 0,
      subTotal: 0,
      vat: 0,
      discountPrice: 0,
      campaignDiscountPrice: 0,
      discountedTotal: 0,
      currency: "₺",
      data: {
        products: this.products,
      },
    };
  },
  methods: {
    ...mapActions("shared", ["searchProduct"]),
    onInput(e) {
      this.doSearch(e.target.value, this.delay);
    },
    doSearch(text, timeout) {
      this.filteredProductList = [];

      let localTimeout = 0;
      if (timeout) {
        localTimeout = timeout;
      }

      if (text && text.trim().length) {
        if (text.trim().length >= this.length) {
          this.emitSearch(text, localTimeout);
        }
      }
    },
    emitSearch(text, timeout) {
      clearTimeout(this.timer);
      this.onBlur();
      this.ready = false;
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        this.searchProduct({
          query: text,
          type: this.name, // Prop name
          onSuccess: (result) => {
            var items = result.data;
            if (this.productId)
              items = items.filter((A) => A.id != this.productId);
            helper.copy(items, this.filteredProductList);
            this.ready = true;
            this.selectDropVisible = true;
            this.$refs.searchInput.focus();
          },
        });
      }, timeout);
    },
    onBlur() {
      if (this.multipleSelect) return;
      setTimeout(() => {
        this.selectDropVisible = false;
      }, 250);
    },
    onClose() {
      this.selectDropVisible = false;
    },
    selectProduct(product, event = null) {
      if (this.name == "cross") {
        product.new = 1;
      }

      const index = this.computedData.findIndex((cd) => cd.id === product.id);
      if (index == -1) {
        product.quantity = 1;
        product.category = product.category ? product.category.name : null;
        const vatAmount = product.vat_amount || this.vatAmount;
        if (this.vatType == "included") {
          // Fiyatlara KDV Dahil ise, ürün fiyatından kdv çıkar
          product.price = helper.vatExcludedPrice(vatAmount, product.price);
        }
        if(this.limit > 0 && this.limit == this.computedData.length) {
        }

        this.computedData.push(product);
      } else if (this.name !== "cross") {
        if (this.multipleSelect) {
          this.computedData.splice(index, 1);
        } else {
          this.computedData[index].quantity++;
          this.computedData.sort();
        }
      }
      if (!this.multipleSelect) this.clearList();
    },
    clearList() {
      this.searchTerm = "";
      this.ready = false;
      this.filteredProductList = [];
    },
    onCreate() {
      this.$emit("on-create");
    },
    onAction(data) {
      if (data.key == "remove") {
        let message = "Silme işlemini onaylıyor musunuz ?";
        this.$swal({
          title: message,
          showCancelButton: true,
          cancelButtonText: "İptal",
          confirmButtonText: "Sil",
          icon: "warning",
        }).then((confirm) => {
          if (confirm.isConfirmed) {
            this.onDelete(data);
          }
        });
      }

      if (data.key == "edit") {
        this.$emit("on-edit", data);
      }
    },
    onDelete(data) {
      this.$emit("on-remove", data);
      if (this.removeSelf) {
        this.computedData.splice(data.index, 1);
      }
    },
    onQtyChange(data) {
      if (this.quantity) {
        let index = helper.arrayFind(this.computedData, "id", data.row.id);
        this.computedData[index].quantity = data.value;
        this.calculatePrice();
      }
    },
    priceChange(data){
      let index = helper.arrayFind(this.computedData, "id", data.row.id);
      this.computedData[index].price = data.value;
      this.calculatePrice();
    },
    calculatePrice() {
      this.subTotal = 0;
      this.discountPrice = 0;
      this.campaignDiscountPrice = 0;
      this.discountedTotal = 0;
      this.vat = 0;
      this.totalPrice = 0;

      if (this.computedData && this.computedData.length) {
        for (let i = 0; i < this.computedData.length; i++) {
          const product = this.computedData[i];
          const productTotalPrice = Number(product.price * product.quantity);
          const productDiscountedTotal = Number(
            product.discount_price * product.quantity
          );

          this.subTotal += productTotalPrice; // Ara Toplam
          this.campaignDiscountPrice += this.campaignDiscount; // Kampanya İndirim Tutarı
          this.discountedTotal += productDiscountedTotal; // İndirimli Toplam Tutar
          this.discountPrice += this.subTotal - productDiscountedTotal; // İndirim Tutarı

          // Ürün kdv tutarı
          const vatPrice =
            (this.discountedTotal || this.subTotal) -
            this.campaignDiscountPrice;
          const vatAmount = product.vat_amount || this.vatAmount;
          this.vat = helper.vatAmount(vatAmount, vatPrice); // KDV Tutar

          // Para Birimi
          this.currency = {
            id: product.currency_code.id,
            code: product.currency_code.code,
            symbol: product.currency_code.symbol,
          };
        }
        // Genel Toplam -> İndirimli Toplam yada Ara Toplam - Kampanya indirimi + KDV tutarı
        this.totalPrice =
          (this.discountedTotal || this.subTotal) -
          this.campaignDiscountPrice +
          this.vat;
      }

      let data = {
        totalPrice: this.totalPrice,
        vatExcluded: this.totalPrice - this.vat,
        currency: this.currency,
      };

      this.$emit("total-changed", data);
      this.formatPrices();
    },
    formatPrices() {
      this.campaignDiscountPrice = this.campaignDiscountPrice
        ? localization.getMoneyFormat(
            this.currency.code,
            this.campaignDiscountPrice
          )
        : 0;
      this.discountPrice = this.discountPrice
        ? localization.getMoneyFormat(this.currency.code, this.discountPrice)
        : 0;
      this.discountedTotal = this.discountedTotal
        ? localization.getMoneyFormat(this.currency.code, this.discountedTotal)
        : 0;
      this.subTotal = this.subTotal
        ? localization.getMoneyFormat(this.currency.code, this.subTotal)
        : 0;
      this.vat = this.vat
        ? localization.getMoneyFormat(this.currency.code, this.vat)
        : 0;
      this.totalPrice = this.totalPrice
        ? localization.getMoneyFormat(this.currency.code, this.totalPrice)
        : 0;
    },
    checkImageExists(product) {
      if (product.image) {
        switch (typeof product.image) {
          case "object":
            return product.image.path;

          default:
            return product.image;
        }
      }
    },
    getImageSrc(product) {
      switch (typeof product.image) {
        case "object":
          return product.image.path;

        default:
          return product.image;
      }
    },
  },
  model: {
    prop: "products",
  },
  computed: {
    ...mapState({
      config: (state) => state.session.config,
    }),
    getTitle() {
      return this.title.toLocaleUpperCase();
    },
    componentId() {
      return this.name + "-" + helper.getRandomInteger(1, 999999);
    },
    computedData: {
      get() {
        return this.products;
      },
      set(value) {
        this.data.products = value;
      },
    },
    vatType() {
      return this.config["site.vat_type"] || "excluded";
    },
    vatAmount() {
      return this.config["site.vat"] || 18;
    },
  },
  watch: {
    products: function (newVal, oldVal) {
      if (this.quantity && newVal) {
        this.calculatePrice();
      }
    },
  },
  mounted() {
    if (this.quantity) {
      this.calculatePrice();
    }
  },
};
</script>
<style lang="scss" scoped>
.accordion-overflow {
  overflow-x: hidden;
}
.result-count {
  position: absolute;
  right: 10px;
  z-index: 1060;
  top: 7px;
}
.result-card {
  max-height: 250px;
  overflow: auto;
}
.compitable span {
  padding: 10px;
}
.close-icon {
  cursor: pointer;
  font-weight: 600;
}
</style>
